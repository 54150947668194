<template>
    <v-row no-gutters class="fill-height mt-10 mx-10">
        <v-col cols="2" align="center">
            <v-card width="85%" height="250" 
                @click="$store.commit('setPaymentOptionViewer', true)"
                class="containerBorder1 custom-card">
                <span class="font-weight-bold text-h5">
                    Juvenile Payments
                </span>
            </v-card>
        </v-col>
        <v-col cols="2" align="center">
            <v-card width="85%" height="250" href="https://tx.smartchildsupport.com/main.aspx"
                class="containerBorder1 custom-card">
                <span class="font-weight-bold text-h5">
                    Child Support Payments
                </span>
            </v-card>
        </v-col>
        <!-- <v-col cols="2" align="center">
            <v-card width="85%" height="250" class="d-flex align-center justify-center"
                @click="$store.dispatch('initiateCall')">Telepresence</v-card>
        </v-col> -->
    </v-row>
</template>
<script>
import axios from 'axios';
export default {
    name: "payments",
    methods: {
        makePayment(url) {
            let data = JSON.stringify({
                "url": url
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'localhost:3000/open',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        pullDataLocalStorage() {
            let temp = localStorage.getItem('formcategory')
            temp.forEach(cat => {
                console.log('Retriving from Local Storage', cat)
            })

        }
    }
}
</script>

<style>
.custom-card {
    display: flex;
    align-items: center;
    /* Centers content vertically */
    justify-content: center;
    /* Centers content horizontally */
}</style>