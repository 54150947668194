<template>
    <v-dialog persistent max-width="67%" v-model="getHelpViewer">
        <v-card class="pa-5">
            <v-card-title class="justify-center text-h4 pb-7 font-weight-bold ma-0"> {{ $t('header') }}
            </v-card-title>
            <div style="position: absolute; align-self: end; right: 0; top: 0">
                <v-btn icon class="mr-5 mt-3" @click="$store.commit('closeTelepresenceViewer')">
                    <v-icon color="red" x-large>mdi-window-close</v-icon>
                </v-btn>
            </div>
            <v-divider></v-divider>
            <v-card-text align="center" class="pa-0 mt-5">
                <v-card width="90%" height="60" color="primary" @click="showRequestForm()"
                    class="white--text text-h5 my-1 justify-center align-center">
                    <v-card-text class="white--text text-h5"> {{ $t('option1') }} </v-card-text>
                </v-card>
                <v-card width="90%" height="60" color="primary" @click="initiateCall()"
                    class="white--text text-h5 my-1 justify-center align-center">
                    <v-card-text class="white--text text-h5"> {{ $t('option2') }} </v-card-text>
                </v-card>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: "telepresenceViewer",
    computed: {
        ...mapGetters(['getHelpViewer'])
    },
    methods: {
        initiateCall() {
            this.$store.commit('setHelpViewer', false)
            this.$store.dispatch('initiateCall')
        },
        showRequestForm(){
            this.$store.commit('setHelpViewer', false)
            this.$store.commit('setRequestFormViewer', true)
            // open request form here
        }
    }
}
</script>
<i18n>
{
    "en": {
        "header": "Select an option you prefer",
        "option1": "Submit your inquiry via email",
        "option2": "Connect to live assistance"
      },
    "es":{
        "header": "Selecciona una opción que prefieras",
        "option1": "Ir a la Biblioteca de Derecho",
        "option2": "Conéctese a la asistencia en vivo"
      }
  }
</i18n>