export default {
  state: {
    avatarLoaded: false,
    currentLanguage: 'en',
    avatarSpeech: '',
    textBubbleText: '',
    isSpeaking: false,
    defaultBubbleText: false,
    count: 0,
    avatarLangCodes: null,
    script: null,
    sitepalId: '',
    avatarId: '',
    avatarName: ''
  },
  getters: {
    getCurrentLanguage: state => state.currentLanguage,
    getAvatarSpeech: state => state.avatarSpeech,
    getTextBubbleText: state => state.textBubbleText,
    getIsSpeaking: state => state.isSpeaking,
    getAvatarLoaded: state => state.avatarLoaded,
    getDefaultBubbleText: state => state.defaultBubbleText,
    getAvatarLangCodes: state => state.avatarLangCodes,
    getSitepalID: state => state.sitepalId,
    getAvatarID: state => state.avatarId,
    getAvatarName: state => state.avatarName
  },
  mutations: {
    setCurrentLanguage(state, lang) {
      state.currentLanguage = lang;
    },
    setIsSpeaking(state, status) {
      state.isSpeaking = status;
    },
    setAvatarSpeech(state, speech) {
      state.avatarSpeech = speech;
    },
    clearAvatarSpeech(state) {
      state.avatarSpeech = '';
    },
    setTextBubbleText(state, text) {
      state.textBubbleText = text;
    },
    clearTextBubbleText(state) {
      state.textBubbleText = '';
    },
    setAvatarLoaded(state, status) {
      state.avatarLoaded = status;
    },
    setDefaultBubbleText(state, status) {
      state.defaultBubbleText = status
    },
    setAvatarLangCodes(state, langCodeObj) {
      state.avatarLangCodes = langCodeObj
    },
    setCount(state, count) {
      state.count = count
    },
    setSitepalId(state, id) {
      state.sitepalId = id
    },
    setAvatarId(state, id) {
      state.avatarId = id
    },
    setAvatarName(state, name) {
      state.avatarName = name
    }
  },
  actions: {
    avatarSpeak({ getters, commit }, speech) {
      return new Promise((resolve, reject) => {
        if (speech === null || speech === '') return reject('Nothing to speak');
        commit('clearAvatarSpeech');

        setTimeout(() => {
          window.sayText(
            speech,
            getters.getAvatarLangCodes.voiceId,
            getters.getAvatarLangCodes.langId,
            getters.getAvatarLangCodes.engineId
          )
          commit('setTextBubbleText', speech);
        }, 100);
        
        resolve('Speech request sent to SitePal')
      })
    },
    avatarDetails({ commit, dispatch }, avatarInfo) {
      commit('setSitepalId', avatarInfo.sitepalId)
      commit('setAvatarId', avatarInfo.avatarId)
      commit('setAvatarName', avatarInfo.avatarName)
      dispatch('identifyAvatarLangCodes', avatarInfo)
    },
    loadAvatar({ getters, state }) {

      if (state.count === 0) {
        state.count = 1
        var sc = document.createElement('script');
        sc.type = "text/javascript"
        sc.id = "scId"
        sc.src = "//vhss-d.oddcast.com/vhost_embed_functions_v4.php?acc=" + getters.getSitepalID + "&js=0";

        //sc.src = this.getSitepalID
        sc.onload = () => {
          setTimeout(() => {
            if (typeof AC_VHost_Embed == 'function') {
              state.script = document.createElement('script');
              state.script.type = "text/javascript";
              state.script.id = 'scriptID'
              state.script.innerHTML =
                "AC_VHost_Embed(7295865,960,960,'',1,0," + getters.getAvatarID + ",0,1,1,'RwZIxMafWux6BWjhauvuPFZP3UcJLcqh',0, '400|300|15|15|L|T|false|0|0|0|0|0|C|0|0|0|992')";
              document.getElementById('divVHSS').appendChild(state.script);
            }
          }, 0)
        }
        document.head.appendChild(sc);
      }
    },
    identifyAvatarLangCodes({ getters, commit }, avatarInfo) {
      console.log('Avatar information to set the language', avatarInfo)
      if (getters.getCurrentLanguage === 'es-US') {
        commit("setAvatarLangCodes", avatarInfo.langCodes.es)
      } else {
        commit("setAvatarLangCodes", avatarInfo.langCodes.en)
      }
    },
    applicationRefereshScheduler({ dispatch, state }) {
      const currentDate = new Date()
      const hour = currentDate.getHours()
      console.log('Current time', hour)
      if (hour === 11) {
        location.reload()
        setTimeout(() => { dispatch('applicationRefereshScheduler') }, 3600000)
      } else {
        setTimeout(() => { dispatch('applicationRefereshScheduler') }, 3600000)
      }
    }
  }
}
