<template >
  <div :class="keyboardClass" v-if="!getKioskProfile.data().physicalKeyboard"></div>
</template>


<script>
import Keyboard from 'simple-keyboard';
import "simple-keyboard/build/css/index.css";
import { mapGetters } from "vuex";

export default {
  name: "SimpleKeyboard",
  props: {
    keyboardClass: {
      default: "simple-keyboard",
      type: String
    },
    input: {
      type: String
    }
  },
  data: () => ({
    keyboard: null
  }),
  computed: {
    ...mapGetters(["getKioskProfile", "getClearInput"]),
  },
  mounted() {
    const kbClass = "." + this.keyboardClass;
    this.keyboard = new Keyboard(kbClass, {
      theme: "simple-keyboard hg-theme-default hg-layout-default",
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      preventMouseDownDefault: true
    });
  },
  methods: {
    onChange(input) {
      console.log('Input on onchange simplekey: ', input)
      this.$emit("onChange", input);
    },
    onKeyPress(button) {
      this.$emit("onKeyPress", button);

      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === "{shift}" || button === "{lock}") this.handleShift();
    },
    handleShift() {
      let currentLayout = this.keyboard.options.layoutName;
      let shiftToggle = currentLayout === "default" ? "shift" : "default";

      this.keyboard.setOptions({
        layoutName: shiftToggle
      });
    }
  },
  watch: {
    input(input) {
        this.keyboard.setInput(input);
    },
    getClearInput(newVal){
      if(newVal){
        this.keyboard.clearInput();
      }
    }
  }
}
</script>

<style scoped>

</style>
