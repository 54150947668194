import { UniqueDirectiveNamesRule } from 'graphql';
import * as fb from "@/firebase/index.js";
import router from '../../router';
import axios from 'axios';
export default {
  state: {
    kioskProfile: {},
    kioskId: undefined,
    count: 0,
    kioskModules: [],
    dataloadInit: false,
    qnaEndpointEN: {},
    qnaEndpointES: {}
  },
  getters: {
    getKioskId: state => state.kioskId,
    getKioskProfile: state => state.kioskProfile,
    getQnaEndpointEN: state => state.qnaEndpointEN,
    getQnaEndpointES: state => state.qnaEndpointES,
    getCMSlink: state => state.kioskProfile.data().cmsLink,
    getKioskType: state => state.kioskProfile.data().email.split('@')[0],
    getKioskModules: state => state.kioskModules,
    getDataLoadInit: state => state.dataloadInit,
    getTempAvatarInfo: state => state.tempAvatarInfo
  },
  mutations: {
    setKioskId(state, kioskId) {
      state.kioskId = kioskId;
    },
    setKioskProfile(state, profile) {
      state.kioskProfile = profile;
    },
    setKioskModules(state, module) {
      state.kioskModules.push(module)
    },
    setDataloadInit(state, status) {
      state.dataloadInit = status
    },
    setQnaEndpointEN(state, qnaObj) {
      state.qnaEndpointEN.enable = qnaObj.enable
      state.qnaEndpointEN.kbName = qnaObj.kbName
    },
    setQnaEndpointES(state, qnaObj) {
      state.qnaEndpointES.enable = qnaObj.enable
      state.qnaEndpointES.kbName = qnaObj.kbName
    },
    setTempAvatarInfo(state, obj) {
      state.tempAvatarInfo = obj
    },
    setAvatarDetails(state, avatarDetail) {

    }
  },
  actions: {
    login({ dispatch }, loginObj) {
      return new Promise((resolve, reject) => {
        fb.auth.signInWithEmailAndPassword(loginObj.email, loginObj.password)
          .then(cred => {
            dispatch('fetchKioskProfile', cred.user);
            resolve('Successfully LoggedIn');
            router.push('/loadData')
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },
    fetchKioskProfile({ commit, dispatch, state, getters }, kiosk) {
      fb.kioskCollection.doc(kiosk.uid)
        .onSnapshot(kioskData => {
          if (kioskData.data() === undefined) {
            dispatch('logout')
          } else {
            // Setting Kiosk ID
            commit('setKioskId', kiosk.uid);
            // Setting Kiosk Data 
            commit('setKioskProfile', kioskData);

            // Checking refresh of the kiosk from Dahboard
            dispatch('autoRefreshKiosk', kioskData)

            // To initiate data load from the data fetched from the firebase.
            if (!getters.getDataLoadInit) {
              commit('setDataloadInit', true)
              //dispatch('avatarDetails', kioskData.data().avatarList[Math.floor(Math.random() * kioskData.data().avatarList.length)])
              dispatch('initiateDataPull', kioskData)
              commit('setQnaEndpointEN', kioskData.data().englishQna)
              commit('setQnaEndpointES', kioskData.data().spanishQna)
              if (getters.getAvatarID !== kioskData.data().avatarInfo.avatarId && getters.getAvatarID !== '') {
                dispatch('applicationRefereshScheduler')
              }
            }

            if (getters.getAvatarName === '') {
              dispatch('avatarDetails', kioskData.data().currentAvatar)
            } else {
              if (getters.getAvatarName !== kioskData.data().currentAvatar.avatarName) {
                dispatch('avatarDetails', kioskData.data().currentAvatar)
              }
            }

            if (kioskData.data().enableScreenSaver) {
              commit('setScreenSaver', true)
              router.push('/screenSaver')
            }
          }
        })
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        fb.auth.signOut()
          .then(() => {
            commit('setKioskId', undefined);
            commit('setKioskProfile', {});
            commit('setDataloadInit', false)
            resolve('Successfully Logged Out');
            location.reload();
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },
    manageKioskPresence({ state }, kiosk) {
      var kioskStatusDatabaseRef = fb.firebase.database().ref('/status/' + kiosk.uid);
      //kioskStatusDatabaseRef.keepSynced(true);
      var isOfflineForDatabase = {
        state: 'Unavailable'
      };

      var isOnlineForDatabase = {
        state: 'Available'
      };

      fb.firebase.database().ref('.info/connected').on('value', function (snapshot) {
        // If we're not currently connected, don't do anything.
        if (snapshot.val() === false) return;

        kioskStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function () {
          kioskStatusDatabaseRef.set(isOnlineForDatabase);
        });
      });
    },
    sendEmail({ state }, emailData) {
      return new Promise((resolve, reject) => {
        const mailObj = {
          to: emailData.address,
          message: {
            subject: emailData.subject,
            html: emailData.content,
          }
        }

        var config = {
          method: 'post',
          url: 'https://us-central1-connect-dashboard.cloudfunctions.net/apiV2/mail/a763cd056f1b2405788443b7197e0708',
          headers: {
            'Content-Type': 'application/json'
          },
          data: mailObj
        };

        axios(config)
          .then(function (response) {
            resolve('Email sent successfully')
          })
          .catch(function (error) {
            reject(error.message)
          });

        fb.mailCollection.add(mailObj)
          .then(() => {
            resolve('Email sent successfully')
          })
          .catch(error => {
            reject(error.message)
          })
      })
    },
    sendQnaData({ state, commit }, data) {
      fb.qnaCollection.add(data)
        .then(response => {
          //commit('setUserQnaRequest', response.id);
          console.log("QNA analytics added successfully")
        })
        .catch(error => {
          console.log(error.message);
        })
    },
    // initiateDataPull({ commit, dispatch }, kioskData) {
    //   commit('setkioskLocation', kioskData.data().locationCode)
    //   dispatch('indentifyModuleLang', {
    //     module: kioskData.data().modules,
    //     language: kioskData.data().languages
    //   })
    //   //dispatch('updateAutoReferesh', kioskData)
    // },
    autoRefreshKiosk({ state }, kiosk) {
      fb.kioskCollection.doc(kiosk.id).onSnapshot(snapshot => {
        if (snapshot.data().autoRefresh) {
          fb.kioskCollection.doc(kiosk.id).update({
            autoRefresh: false
          }).then(() => {
            location.reload();
          })
        }
      })
    },
    updateAutoReferesh({ state }, kiosk) {
      if (kiosk.data().autoRefresh) {
        //
      }
    },
    updateKioskProfile({ commit, getters }) {
      fb.kioskCollection.doc(getters.getKioskId).update({
        enableScreenSaver: false
      })
    },
  }
}
