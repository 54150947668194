<template>
  <v-row justify="center" align="start">
    <v-col class="pt-10" cols="12" align="center">
      <span class="text-h2 font-weight-bold grey--text text--darken-3">
        {{ $t('default') }}
      </span>
    </v-col>
    <v-col cols="10" class="py-2">
      <v-row class="fill-height mt-6 mb-6" align="center" justify="center">
        <v-col align="center" :cols="menu.col" v-for="(page, index) in $t('homepages')" :key="index" class="mb-2">
          <v-card @click="goto(page)" class="menuItem d-flex flex-column align-center justify-center"
            :width="menu.width" :height="menu.height" elevation="5">
            <v-img :src="page.menuIcon.url" :width="menu.imageWidth" contain>
            </v-img>
            <v-card-text class="text-h5 primary--text font-weight-bold">
              {{ page.menuName }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-divider class="pb-6 mx-10"></v-divider>
      <languageBar></languageBar>
    </v-col>
  </v-row>
</template>

<script>
import languageBar from '@/components/languageBar'
import { mapGetters } from 'vuex'

export default {
  name: 'menuMatrix',
  components: {
    languageBar
  },
  data() {
    return {
      menu: {
        height: null,
        width: null,
        col: null,
        imageWidth: null
      }
    }
  },
  computed: {
    ...mapGetters(['getCMSlink', 'getTouchCount', 'isUserLooking'])
  },
  methods: {
    goto(page) {
      if (page.pageType.length !== 0) {
        switch (page.pageType[0].__typename) {
          case 'ComponentHomepageNavigation':
            this.$store.commit("setIsThinking", false)
            this.$store.commit('setRequestQna', false)
            this.$store.commit("setSpeechRecognitionHandler", false);
            this.$router.push('/' + page.pageType[0].pathName.toLowerCase());
            this.$store.commit('setPageHeader', page.pageType[0].pageHeader)
            this.$store.commit('setQnaKeyword', '')

            // To start session using touch - when no face detected
            if (!this.isUserLooking) {
              this.$store.commit('setTouchCount', this.getTouchCount + 1)
              if (this.getTouchCount === 1) {
                // start time
                this.$store.commit('setStartUserSession', new Date())

                //session start object
                this.$store.commit('setTouchRequest', {
                  module: 'Default',
                  action: 'Touch Detected',
                  response: 'User Session Started - Touch Detected',
                  timeStamp: new Date(),
                  requestType: 'Session'
                })
              }
            }

            // Will be called irrespective of Touch Count
            this.$store.commit('setTouchRequest', {
              module: 'Home Page',
              action: 'navigate',
              response: page.pageType[0].pathName,
              timeStamp: new Date(),
              requestType: 'Touch'
            })


            this.$store.commit('setDefaultBubbleText', true)
            this.$store.dispatch('avatarSpeak', page.speech)
            break;
          case 'ComponentHomepageDialogBox':
            var temp = []
            console.log('AT open dialog box: ', page.pageType[0].type)
            if (page.pageType[0].type === 'textList') {
              console.log('List : ', page.pageType[0].textList.split(","))
              page.pageType[0].textList.split(",").forEach(option => {
                let data = {
                  displayText: option
                }
                temp.push(data)
              })
              this.$store.dispatch('openOptionsViewer', {
                dialogHeader: 'Please select case type',
                dialogData: temp,
              })
            }
            break;
          default:
            break;
        }
      }

    },
  },
  mounted() {
    if (this.$i18n.t('homepages').length <= 4) {
      this.menu = {
        height: 270,
        width: 280,
        col: 5,
        imageWidth: "60%"
      }
    } else if (this.$i18n.t('homepages').length <= 6) {
      this.menu = {
        height: 260,
        width: 275,
        col: 4,
        imageWidth: "55%"
      }
    }
  }
}
</script>

<style>
.menuItem {
  border: solid #6b1530 !important;
}

.footer {
  bottom: 0 !important;
}
</style>
<i18n>
    {
      "en": {
          "default": "How Can I Help You?"
        },
      "es":{
          "default": "¿Le puedo ayudar en algo?"
        }
    }
  </i18n>