<template>
    <v-dialog v-model="getPaymentOptionViewer" max-width="45%" persistent>
        <v-card>
            <v-card-title class="justify-center text-h5">Please select an option you prefer</v-card-title>
            <div style="position: absolute; align-self: end; right: 0; top: 0">
                <v-btn icon @click="$store.commit('setPaymentOptionViewer', false)" class="mr-5 mt-3">
                    <v-icon color="red" x-large>mdi-window-close</v-icon>
                </v-btn>
            </div>
            <v-divider class="pb-4"></v-divider>
            <v-card-actions class="justify-center pa-0 ma-0">
                <v-card color="primary" class="pt-2" flat width="75%" height="50"
                    href="https://juv.epcounty.com/Identity/Account/Login?returnUrl=%2FUserSummary"
                    @click="$store.commit('setPaymentOptionViewer', false)">
                    <v-card-text class="white--text text-h6 pa-0" align="center">
                        Do you have an account?
                    </v-card-text>
                </v-card>
            </v-card-actions>
            <v-card-actions class="justify-center px-0 mx-0">
                <v-card color="primary" class="pt-2" flat width="75%"
                    @click="$store.commit('setPaymentOptionViewer', false)" height="50" href="https://payments.msbpay.navient.com/EPJuvenileProbation">
                    <v-card-text class="white--text text-h6 pa-0" align="center">
                        Pay without Sign-in
                    </v-card-text>
                </v-card>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: "paymentOptionsViewer",
    computed: {
        ...mapGetters(['getPaymentOptionViewer'])
    }
}
</script>