import axios from "axios";
import router from "@/router";
import i18n from "../../i18n";
export default {
    state: {
        qnaStatus: null,
        qnaKeyword: '',
        wrongAnswerCount: 0,
        court: '',
        faqCategory: '',
        isThinking: false,
        requestQna: true
    },
    getters: {
        getQnaStatus: state => state.qnaStatus,
        getQnaKeyword: state => state.qnaKeyword,
        getWrongAnsCount: state => state.wrongAnswerCount,
        getCourt: state => state.court,
        getFaqCategory: state => state.faqCategory,
        getIsThinking: state => state.isThinking,
        getRequestQna: state => state.requestQna
    },
    mutations: {
        setQnaStatus(state, status) {
            state.qnaStatus = status;
        },
        setQnaKeyword(state, keyword) {
            state.qnaKeyword = keyword
        },
        setWrongAnswerCount(state, count) {
            state.wrongAnswerCount = count
        },
        setCourt(state, court) {
            state.court = court
        },
        setFaqCategory(state, category) {
            state.faqCategory = category
        },
        setIsThinking(state, status) {
            state.isThinking = status
        },
        setRequestQna(state, status) {
            state.requestQna = status
        }
    },
    actions: {
        requestQnA({ getters, dispatch, commit }, transcript) {
            if (getters.isThinking) {
                commit("setSpeechTranscript", transcript);
                commit("setTextBubbleText", transcript);
            }
            // Request to QNA
            let data = JSON.stringify({
                "top": 3,
                "question": transcript,
                "includeUnstructuredSources": true,
                "confidenceScoreThreshold": "0.2",
                "filters": {
                    "metadataFilter": {
                        "metadata": [
                            {
                                "key": "location",
                                "value": "common"
                            },
                            {
                                "key": "location",
                                "value": getters.getKioskProfile.data().kioskType.toLowerCase()
                            }
                        ],
                        "logicalOperation": "OR"
                    }
                }
            });
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://ars-studio-en.cognitiveservices.azure.com/language/:query-knowledgebases?projectName=' + i18n.t('qnaEndpoint').kbName + '&api-version=2021-10-01&deploymentName=production',
                headers: {
                    'Ocp-Apim-Subscription-Key': 'da55d74bdec54b7b856aef2a8e8bbded',
                    'Content-Type': 'application/json'
                },
                data: data
            };
            commit("setIsThinking", true)
            commit("setTextBubbleText", "Thinking....")
            axios.request(config)
                .then((response) => {
                    const qnaAnalytics = {
                        timeStamp: new Date(),
                        kioskID: getters.getKioskId,
                        transcript: transcript,
                        result: response.data,
                    }
                    commit('setTouchRequest', {
                        module: 'QnA',
                        action: transcript,
                        response: response.data.answers[0].answer,
                        timeStamp: new Date(),
                        requestType: 'Speech'
                    })
                    dispatch('addQnaDatatoStrapi', qnaAnalytics)
                    console.log('Response from QNA: ', response.data.answers[0])
                    if (response.data.answers[0].confidenceScore > 0.6) {
                        dispatch('generateAnswer', response.data.answers[0])
                    } else if (response.data.answers[0].confidenceScore > 0.45) {
                        if (getters.getCurrentLanguage === 'en') {
                            dispatch('avatarSpeak', 'I didn\'t quite get that, did you mean to ask following questions. Please tap your preference.')
                        } else {
                            dispatch('avatarSpeak', 'No entendía eso, querías hacer las siguientes preguntas. Por favor, toque su preferencia.')
                        }
                        let followUp = [];
                        response.data.answers.forEach(output => {
                            if (output.confidenceScore > 0.45)
                                followUp.push(output.questions[0])
                        });
                        var headerVal;
                        if (getters.getCurrentLanguage === 'en') {
                            headerVal = 'Please Tap the option you prefer'
                        } else {
                            headerVal = 'Por favor, toque la opción que prefiera'
                        }
                        dispatch('openOptionsViewer', {
                            dialogHeader: headerVal,
                            dialogData: followUp,
                        })
                        commit("setIsThinking", false)
                    }
                    else {
                        const currentDate = new Date();
                        const timestamp = currentDate.getHours()
                        console.log('Current time: ', timestamp)
                        if (timestamp > 8 && timestamp < 17) {
                            let mapPath = getters.getMaps.get(getters.getDefaultLocation.toLowerCase())
                            dispatch('openImageViewer', {
                                name: "Clerk's Office",
                                url: mapPath.mapImage,
                                floor: '1st Floor'
                            })
                            dispatch('avatarSpeak', "Sorry! I don't know answer to this question. Please go to the Clerk's office for more information.")
                        } else {
                            commit('setRequestFormViewer', true)
                        }
                        commit("setIsThinking", false)
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        generateAnswer({ dispatch, commit, getters }, result) {
            if (getters.getIsThinking) {
                commit('setQnaStatus', false)
                const metadata = result.metadata.intent
                const ansObj = result
                commit('setDefaultBubbleText', false)
                commit('closeOptionsViewer')
                switch (metadata) {
                    case "navigate":
                        var menuName = ansObj.metadata.navigateto
                        router.addRoute({ path: menuName.toLowerCase(), component: () => import('@/views/' + menuName) });
                        if (router.currentRoute.path !== menuName) {
                            router.push(menuName).then(() => {
                                commit('setQnaKeyword', '')
                                dispatch('avatarSpeak', ansObj.answer);
                                let page = i18n.t('homepages').filter(page => page.pageType[0].pathName === menuName)
                                commit('setPageHeader', page[0].pageType[0].pageHeader)
                            })
                        } else {
                            commit('setQnaKeyword', '')
                            dispatch('avatarSpeak', ansObj.answer)
                        }
                        break;

                    case "showmap":
                        try {
                            var mapPath = i18n.t('maps').filter(map => map.displayPosition.toString() === ansObj.metadata.mapposition)
                            console.log('Map Path: ', mapPath)
                            var floorName = i18n.t('floors').filter(floor => floor.floorNumber === mapPath[0].mapFloor)
                            console.log('Floors: ', i18n.t('floors'))
                            console.log('Map Floor: ', floorName)
                            if (ansObj.answer.trim() !== 'default') {
                                dispatch('avatarSpeak', ansObj.answer + ' ' + mapPath[0].speech);
                            } else {
                                dispatch('avatarSpeak', mapPath[0].speech);
                            }
                            dispatch('openImageViewer', {
                                name: mapPath[0].mapName,
                                url: mapPath[0].mapImage,
                                floor: floorName[0].floorName
                            })

                        } catch (e) {
                            mapPath = getters.getMaps.get(getters.getDefaultLocation.toLowerCase())
                            dispatch('openImageViewer', {
                                name: "Clerk's Office",
                                url: mapPath.mapImage,
                                floor: '1st Floor'
                            })
                            dispatch('avatarSpeak', i18n.t('mapNotFound'))
                        }

                        break;

                    case "speak":

                        dispatch('avatarSpeak', ansObj.answer);
                        break;

                    case "showform":
                        try {
                            console.log('At showform : ', ansObj.metadata)
                            var formName = ansObj.metadata.formname
                            console.log('Form Name from QNA: ', formName)
                            i18n.t('forms').forEach(form => {
                                if (form.formName.toLowerCase() === formName) {
                                    dispatch('openPdfViewer', {
                                        url: form.document,
                                        name: form.formName,
                                        emailLink: form.documentUrl,
                                        type: 'pdf'
                                    })
                                    dispatch('avatarSpeak', ansObj.answer);
                                }
                            })
                        } catch (e) {
                            mapPath = getters.getMaps.get(getters.getDefaultLocation.toLowerCase())
                            dispatch('openImageViewer', {
                                name: "Clerk's Office",
                                url: mapPath.mapImage,
                                floor: '1st Floor'
                            })
                            dispatch('avatarSpeak', i18n.t('formNotFound'))
                        }
                        break;
                    case "searchbykeyword":
                        commit("setQnaKeyword", "")
                        router.addRoute({ path: '/faqs', component: () => import('@/views/faqs') })
                        if (router.currentRoute.path !== '/faqs') {
                            router.push('/faqs')
                        }
                        var page = i18n.t('homepages').filter(page => page.pageType[0].pathName === 'faqs')
                        commit('setPageHeader', page[0].pageType[0].pageHeader)
                        var linkId = ansObj.metadata.linkid
                        commit("setQnaKeyword", linkId)
                        dispatch('avatarSpeak', ansObj.answer);
                        break;

                    case "showformcategory":

                        var formKeyword = ansObj.metadata.keyword
                        router.addRoute({ path: '/forms', component: () => import('@/views/forms') })
                        if (router.currentRoute.path !== '/forms') {
                            router.push('/forms')
                        }
                        commit('setPageHeader', 'Forms')
                        commit('setQnaKeyword', formKeyword)
                        dispatch('avatarSpeak', ansObj.answer);
                        break;

                    case "showhours":
                        dispatch('openCourtTimingViewer', {})
                        dispatch('avatarSpeak', ansObj.answer);

                        break;

                    case "session":
                        var action = ansObj.metadata.find(({ name }) => name === 'action').value
                        if (action === "still there") {
                            dispatch('kioskStillThere')
                        } else {
                            dispatch('resetKiosk')
                        }
                        break;

                    case "followup":
                        var prompts = ansObj.dialog.prompts
                        var followUp = [];
                        dispatch('avatarSpeak', ansObj.answer);
                        prompts.forEach(item => followUp.push(item));
                        console.log('Follow ups: ', followUp)
                        dispatch('openOptionsViewer', {
                            dialogHeader: i18n.t('selectOptionMsg'),
                            dialogData: followUp,
                        })
                        break;
                    default:
                        mapPath = getters.getMaps.get(getters.getDefaultLocation.toLowerCase())
                        dispatch('openImageViewer', {
                            name: "Clerk's Office",
                            url: mapPath.mapImage,
                            floor: '1st Floor'
                        })
                        dispatch('avatarSpeak', i18n.t('kbError'))
                        break;
                }
            }
        }

    }
}
