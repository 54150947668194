<template>
  <v-row no-gutters align="center" justify="center">
    <v-col cols="5"></v-col>
    <v-col cols="2">
      <v-row no-gutters>
        <v-col cols="3" v-for="(language, i) in getLanguages" :key="i" class="mx-3">
          <v-btn v-bind:color="getCurrentLanguage === language ? 'primary' : 'grey lighten-2'" class="btnDim"
            height="60px" width="120px" @click="changeLanguage(language)"> {{ pullLanguage(language) }} </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="5">
      <!-- <v-img src="@/assets/ada.png" width="125" contain @click="$store.commit('setEnableADA', true)"
        v-if="!getenableADA"></v-img>
      <v-btn fab color="#0443cc" v-else @click="$store.commit('setEnableADA', false); adaTracking()" width="80" height="80"
        class="ma-0 pa-0">
        <v-icon color="white" size="45">mdi-restore</v-icon>
      </v-btn> -->

    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "languageBar",
  data() {
    return {
      languages: [
        { name: 'English', code: 'en' },
        { name: 'Spanish', code: 'es-US' },
      ]
    }
  },
  computed: {
    ...mapGetters(["getTouchCount", "getCurrentLanguage", "getLanguages", "getenableADA"]),
  },
  methods: {
    pullLanguage(code) {
      return this.languages.filter(lang => lang.code === code)[0].name
    },
    adaTracking() {
      this.$store.commit("setTouchRequest", {
        module: "General",
        action: "ADA",
        response: "ADA enabled",
        timeStamp: new Date(),
        requestType: "Touch",
      });
    },
    changeLanguage(language) {
      switch (language) {
        case "en":
          this.$store.commit("setTouchCount", this.getTouchCount + 1);
          this.$i18n.locale = "en";
          this.$store.commit("setCurrentLanguage", "en");
          this.$store.dispatch("avatarSpeak", "I am now speaking in english.");
          this.$store.dispatch('setDefaults')
          this.$store.dispatch("identifyAvatarLangCodes", "en");
          this.$store.commit("setSpeechRecognitionLanguage", "en-US")
          this.$store.commit("setTouchRequest", {
            module: "Language Bar",
            action: "Change Language",
            response: "Language changed to English",
            timeStamp: new Date(),
            requestType: "Touch",
          });
          break;
        case "es-US":
          this.$i18n.locale = "es";
          this.$store.commit("setCurrentLanguage", "es-US");
          this.$store.dispatch('avatarSpeak', 'Ahora estoy hablando en español.');
          this.$store.dispatch('setDefaultsSpanish')
          this.$store.dispatch("identifyAvatarLangCodes", "es-US");
          this.$store.commit("setSpeechRecognitionLanguage", "es-US")
          // Track the touch count and action performed.
          this.$store.commit("setTouchCount", this.getTouchCount + 1);
          this.$store.commit("setTouchRequest", {
            module: "Language Bar",
            action: "Change Language",
            response: "Language changed to Spanish",
            timeStamp: new Date(),
            requestType: "Touch",
          });
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style scoped>
.btnDim {
  width: 90%;
  padding-top: 15px;
  padding-bottom: 15px;
}
</style>