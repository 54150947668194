<template>
    <v-dialog v-model="getDepartmentServiceViewer.show" persistent width="30%">
        <v-card height="475px" class="overflow-hidden">
            <v-card-title class="justify-center"> {{ getDepartmentServiceViewer.departmentName }}</v-card-title>
            <v-divider></v-divider>
            <v-card height="360px" flat class="overflow-y-auto">
                <v-card-text class="font-weight-bold">
                    <v-treeview open-all :items="items">
                    </v-treeview>
                </v-card-text>
            </v-card>
            <v-divider></v-divider>
            <v-card-actions class="justify-center">
                <v-btn @click="$store.commit('closeDepartmentServiceViewer')" outlined color="primary" width="150"> Close </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: "departmentServiceViewer",
    computed: {
        ...mapGetters(["getDepartmentServiceViewer"]),
        items() {
            return this.getDepartmentServiceViewer.services
        }
    }
}
</script>