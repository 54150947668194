import * as fb from '../../firebase/index.js'
import crypto from "crypto";
import axios from 'axios';
import router from "@/router/index.js";

export default {
    state: {
        callEndTimer: 0,
        callListener: null,
        meetingDetails: {},
        callDetails: null
    },
    getters: {
        getCallListener: state => state.callListener,
        getMeetingDetails: state => state.meetingDetails,
        getCallDetails: state => state.callDetails
    },
    mutations: {
        setMeetingDetails(state, meetingObj) {
            state.meetingDetails = meetingObj
        },
        setCallDetails(state, obj) {
            state.callDetails = obj
        }
    },
    actions: {
        initiateCall({ getters, dispatch }) {
            fb.groupCollection.where('defaultTelepresence', '==', true).get().then(group => {
                console.log('What is gorup info', group.docs)
                if (group.empty) {
                    dispatch('avatarSpeak', 'Telepresence not set up. Please contact the Law Library.')
                } else {
                    console.log('What is gorup info', group.docs)
                    // dispatch('availableUsers', grp.id)
                    group.docs.forEach(grp => {
                        console.log('Group details: ', grp.data())
                        dispatch('availableUsers', grp.id)
                    })
                }
            })
        },


        availableUsers({ dispatch }, groupID) {
            let users = []
            fb.registeredUsersCollection.where("groups", "array-contains", groupID).where("onlineStatus", "==", "Available").get().then(aUsr => {
                aUsr.docs.forEach(usr => {
                    users.push(usr.id)
                })
                console.log(aUsr)
                if (users.length > 0) {
                    dispatch('startCall', users)
                } else {
                    dispatch('avatarSpeak', 'No users are available. Please try after sometime.')
                }
            })
        },

        startCall({ getters, state, dispatch, commit }, availableUsers) {
            let meetCode = crypto.randomBytes(50).toString("hex").slice(0, 20)
            commit('setMeetingDetails', {
                meetingCode: meetCode
            })
            let callObj = {
                callStatus: "Initiated",
                meetingCode: meetCode,
                startTime: new Date(),
                endTime: null,
                conferenceAttendees: [],
                callerInfo: getters.getKioskProfile.data(),
                callInitiator: { name: getters.getKioskProfile.data().name, id: getters.getKioskProfile.id },
                requestedUsers: availableUsers,
                calledUser: availableUsers,
                availableUsers: availableUsers,
                source: true
            };

            fb.telepresenceCollection.add(callObj).then(response => {
                state.callEndTimer = setTimeout(() => {
                    fb.telepresenceCollection.doc(response.id).update({
                        callStatus: 'Not Answered',
                        endTime: new Date(),
                        requestedUser: null
                    })
                    getters.getCallListener()
                    dispatch(
                        "avatarSpeak",
                        "Please try again after sometime."
                    );
                }, 35000)
                dispatch("handleCall", response.id)
                dispatch("declineCloudFunction", response.id)
            })
        },

        handleCall({ state, dispatch, commit }, callId) {
            state.callListener = fb.telepresenceCollection.doc(callId).onSnapshot(
                call => {
                    switch (call.data().callStatus) {
                        case 'Initiated':
                            // Initiated
                            dispatch('avatarSpeak', 'Hang On! Connecting you to a live person.')
                            break;
                        case 'inProgress':
                            // In Progress
                            commit('setCallDetails', call)
                            clearTimeout(state.callEndTimer)
                            router.push('/telepresence')
                            break;
                        case 'Declined':
                            // Declined
                            dispatch(
                                "avatarSpeak",
                                "All requested users declined the call. Please try again after sometime."
                            );
                            clearTimeout(state.callEndTimer)
                            break;
                        case 'Ended':
                            // Ended
                            break;
                        default:
                            console.log('Something went wrong')
                    }
                }
            )
        },

        declineCloudFunction({ commit }, id) {
            const config = {
                method: 'post',
                url: 'https://us-central1-el-paso-5508e.cloudfunctions.net/apiV2/getCallInfo/a763cd056f1b2405788443b7197e0708',
                params: { call: id, action: 'get' }
            }
            axios(config)
                .then(result => {
                    console.log('Result from axios call', result)
                })
                .catch(error => {
                    console.log('Error : ', error.message)

                })
        },

        handleEndCall({ getters, commit }) {
            console.log('At End call: ', getters.getCallDetails.data())
            fb.telepresenceCollection.doc(getters.getCallDetails.id).update({
                callStatus: 'Ended',
                requestedUser: null
            })
            getters.getCallListener();
            commit('setTextBubbleText', 'Tap the microphone button, to ask me a question.');
        }
    }
}
